<template>
    <div v-if="showSlot">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'TimeoutHandler',
    props: {
        timeout: {
            type: Number,
            default: 30000
        }
    },
    data() {
        return {
            showSlot: false,
            timer: null
        }
    },
    mounted() {
        if (this.$data.timer) {
            clearTimeout(this.$data.timer)
        }
        this.$data.timer = window.setTimeout(() => {
            this.$data.showSlot = true
        }, this.$props.timeout)
    },
    beforeDestroy() {
        clearTimeout(this.$data.timer)
    }
}
</script>
